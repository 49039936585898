document.addEventListener('DOMContentLoaded', function () {
  // タブに対してクリックイベントを適用
  const tabs = document.getElementsByClassName('js-tab');
  for (let i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener('click', () => {
      tabSwitch(i)
    }, false);
  }

  const cards = document.getElementsByClassName('js-card');

  // タブをクリックすると実行する関数
  function tabSwitch(i) {
    // タブのclassの値を変更
    document.getElementsByClassName('is-active')[0].classList.remove('is-active');
    tabs[i].classList.add('is-active');

    // コンテンツのclassの値を変更
    document.getElementsByClassName('is-show')[0].classList.remove('is-show');
    cards[i].classList.add('is-show');
  };
}, false);
